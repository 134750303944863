$color-bg: #FFFFFF;
$color-text: #000000;

$color-black: #000000;
$color-grey: #212121;
$color-grey1: #303030;
$color-grey2: #424242;
$color-grey3: #999999;
$color-grey4: #f6f6f6;

$color-nature-blue: #2182BF;
$color-nature-blue-light: #66ADD9;
$color-nature-forest: #52591E;
$color-nature-green: #898C27;
$color-nature-green-light: #7AC943;
$color-nature-land: #D9B29C;

$color-white: #FFFFFF;