nav {

    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 20px;

    &.main-menu.open {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        border: 1px solid $color-grey2;
        box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.40);
        padding: 40px 100px;
        margin-top: 30px;

        a {
            padding: 10px 0px;
            text-align: center;
        }
    }

    a {
        color: $color-nature-blue;
        text-decoration: none;
        font-weight: bold;
    }

    @media screen and (max-width: $small) {
        justify-content: center;
    }

}

@media screen and (max-width: $small) {
    nav {
        display: none;
        &.main-menu {
            opacity: 0;
            height: 100px;
            transition: height 0.5s ease;
            &.open {
                opacity: 1;
                height: 330px;
            }
        }
    }
}

.burger-menu {
    display: none;
    width: 30px;
    height: 20px;
    position: absolute;
    top: 10px;
    left: 60px;
    cursor: pointer;
  }
  
.bar {
    width: 100%;
    height: 3px;
    background-color: #000;
    position: absolute;
    transition: transform 0.3s ease;
}
  
  .bar:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .bar:nth-child(3) {
    bottom: 0;
  }
  
  .burger-menu.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .burger-menu.open .bar:nth-child(2) {
    opacity: 0;
  }
  
.burger-menu.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

@media screen and (max-width: $small) {
    .burger-menu {
        display: block;
    }
}