.limbu-map-container {

    margin-bottom: 40px;

    .limbu-map-wrapper {
        width: 50%;
        margin: 0px auto;
        // background-color: #76b6c4;

        path {
            cursor: pointer;
            fill: #999999;
        }

        #sankhuwasabha {
            path {
                fill: #999999;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }

        #taplejung {
            path {
                fill: #e6e6e6;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }

        #terathum {
            path {
                fill: #b3b3b3;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }

        #dhankuta {
            path {
                fill: #603813;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }

        #panchthar {
            path {
                fill: #39b54a;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }

        #illam {
            path {
                fill: #93278f;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }

        #jhapa {
            path {
                fill: #3fa9f5;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }

        #morang {
            path {
                fill: #f7931e;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }
        
        #sunsari {
            path {
                fill: #d9e021;
            }
            &:hover {
                path {
                    fill: $color-nature-land;
                }
            }
        }

    }
}

@media screen and (max-width: $small) {
    .limbu-map-container {
        .limbu-map-wrapper {
            width: 100%;
        }
    }
}