* {
	color: $color-text;
}

body {
	background-color: $color-bg;
	font-family: "Montserrat", sans-serif;
}

.designer {
	font-family: "Satisfy", sans-serif;
}

h1 {
	font-size: 130px;
	// font-family: "Satisfy", sans-serif;
	font-family: "Jost", sans-serif;
}

h3 {
	font-size: 22px;
}

h4 {
	font-size: 16px;
	color: $color-grey2;
}

p {
	color: $color-grey1;
}

hr {
	margin: 20px 0px 30px;
}

header {
	min-height: 20px;
}

h1.page-title {
	color: $color-nature-blue;
	font-size: 40px;
	font-weight: bold;
	margin-bottom: 60px;
	margin-top: 40px;
	text-align: center;
}
@media screen and (max-width: $small) {
	h1.page-title {
		line-height: 60px;
	}
}

.page-title-not-found {
	font-size: 32px;
	font-weight: bold;
	margin: 150px 0px;
	text-align: center;
}

.site-block-wrapper {
	display: flex;
	justify-content: center;
	flex-direction: row;
	margin-top: 100px;

	.site-block {
		position: relative;
	}

	@media screen and (max-width: $small) {
		width: 90%;
		margin: 50px auto 0px;

		.site-block {
			h1,
			h4 {
				text-align: center;
			}
		}

		h1 {
			font-size: 80px;
		}
	}
}

.content {
	.content-wrapper {
		width: 90%;
		margin: 0px auto;
		min-height: 635px;
		margin-bottom: 50px;

		p {
			line-height: 22px;
			// margin: 20px 0px;
		}
	}
}

.page-credits {
	h4,
	h3 {
		padding-bottom: 10px;
	}
}

#home-culture-sub-title {
	font-weight: bold;
	color: $color-nature-blue-light;
}

#home-culture-title {
	margin-top: 6px;
	color: $color-nature-blue;
}
.home-state-motto {
	color: $color-nature-green;
}

#silam-sakma-logo {
	width: 65px;
	height: auto;
	position: absolute;
	top: 8px;
	right: -24px;

	img {
		width: 100%;
	}

	@media only screen and (max-width: 600px) {
		width: 50px;
		right: 30px;
	}
}

.image-container {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.image-container.loaded {
	opacity: 1;
}

.hidden {
	display: none;
}

.video-item {
	cursor: pointer;
	width: 500px;
	margin: 50px auto 0px;
	border: 5px solid $color-grey4;

	.video-wrapper {
		padding: 20px;

		video {
			width: 100%;
		}

		.video {
			filter: grayscale(0.8);
		}

		h3 {
			border-radius: 5px;
			display: inline-block;
			font-size: 18px;
			font-family: Roboto;
			margin: 10px 0px;
			padding: 10px 20px;
			background-color: $color-nature-green-light;
		}
		p {
			font-size: 16px;
			font-family: Roboto;
			line-height: 22px;
		}
	}

	&:hover {
		.video {
			filter: none;
		}
	}

	@media only screen and (max-width: 600px) {
		width: 90%;
	}
}

.space-block {
	height: 50px;
}
.space-block-2 {
	height: 100px;
}

.page-history {
	h1.page-title {
		margin-bottom: 40px;
	}
}
