.blogs-wrapper {

    .blog-item {
        cursor: pointer;
        border-bottom: 1px solid #ededed;
        display: flex;
        flex-direction: row;
        gap: 20px;
        height: 260px;
        justify-content: start;
        margin-bottom: 40px;
        overflow: hidden;
        padding: 10px;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: #66ADD9;
        }

        .left-side {
            background-color: #f0f0f0;
            overflow: hidden;
            width: 30%;
            
            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .right-side {
            .title-section {
                padding-top: 10px;
                padding-bottom: 10px;
                h3 {
                    font-weight: bold;
                    padding: 0px;
                    margin-bottom: 16px;
                }
                p {
                    margin: 8px 0px;
                }
            }
            .content-section {
                p {
                    margin: 8px 0px;
                }
            }
        }
        @media screen and (max-width: $small) {
            display: block;
            height: auto;

            .left-side, .right-side {
                width: 100%;
            }

            .left-side {
                height: 150px;
                margin-bottom: 20px;
            }
        }
    }

}