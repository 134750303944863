footer {
    background-color: $color-nature-land;
    padding: 20px 0px;

    .credits-resources {
        width: 90%;
        margin: 0px auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left-side {
            margin-bottom: 10px;
        }

        a {
            color: $color-grey;
            text-decoration: none;
        }

        @media screen and (max-width: $small) {
            flex-direction: column;
            justify-content: start;
        }
    }
}