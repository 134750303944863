.glossary-wrapper {
    h2 {
        font-size: 32px;
        font-weight: bold;
        padding-bottom: 20px;
    }
    h3 {
        font-size: 24px;
        padding-bottom: 6px;
    }

    h2, h3 {
        color: $color-nature-blue;
    }

    .search-box {
        display: flex;
        justify-content: center;

        input {
            border: 1px solid $color-nature-blue;
            padding: 10px 6px;
            border-radius: 10px;
            text-align: center;
            width: 200px;
        }

    }
    
    .item {
        margin-bottom: 16px;

        .link {
            margin: 8px 0px;

            .link-item {
                display: inline-block;
                width: 20px;
                height: 20px;
                background: url('../images/logo-web.png') 2px 2px no-repeat;
                border: 1px solid black;
                border-radius: 50%;
                margin-right: 6px;
                padding: 12px;
                &:hover {
                    background-color: $color-nature-blue-light;
                    border-color: $color-grey3;
                }
            }

            .link-wiki {
                background: url('../images/logo-wiki-small.png') 2px 4px no-repeat;
            }
        }
    }

    .glossary-item {
        margin-bottom: 40px;
    }
}