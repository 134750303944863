.box-icon {
    border: 4px solid $color-nature-forest;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-top: 8px;
    margin-right: 6px;
}

.statement-wrapper {
    display: flex;
    flex-direction: row;

    p {
        padding-top: 2px;
    }

    @media screen and (max-width: $small) {

        margin-top: 40px;
        justify-content: center;
        flex-direction: column;

        .box-icon {
            display: block;
            margin: 0px auto 20px;
        }

        p {
            padding-top: 0px;
        }
    }
}

.top-bar {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 101;
    width: 100%;
    height: 15px;
    display: flex;
    flex-direction: row;

    .top-bar-item {
        background-color: $color-grey;
        width: 25%;
    }

    .top-bar-1 {
        background-color: $color-nature-blue;
    }
    
    .top-bar-2 {
        background-color: $color-nature-blue-light;
    }

    .top-bar-3 {
        background-color: $color-nature-green;
    }

    .top-bar-4 {
        background-color: $color-nature-land;
    }

}
.four-box-wrapper {
    width: 760px;
    margin: 80px auto 80px auto;

    .four-box {
        display: grid;
        grid-template-columns: 240px 240px 240px;
        grid-gap: 40px;
        justify-content: center;
        color: #444;
    
        .four-box-item {
            background-color: $color-nature-blue;
            box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);
            text-align: center;
    
            &:hover {
                cursor: pointer;
                background-color: $color-nature-blue-light;
            }
    
            a {
                display: inline-block;
                padding: 55px 95px;
                color: $color-white;
                text-decoration: none;
            }
        }
        
    }

    @media screen and (max-width: $small) {
        width: 90%;
        margin-top: 60px;
        
        .four-box {
            grid-template-columns: 100%;

            .four-box-item {
                a {
                    padding: 45px 92px;
                }
            }
        }
    }
}

@media screen and (max-width: $medium) {
    .four-box-wrapper {
        width: auto;
        .four-box {
            grid-template-columns: 240px;
        }
    }
}

.heart-icon {
    margin-top: 20px;
}